import { useEffect, useState, useMemo, ReactElement } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Space } from 'antd'
import cn from 'classnames'
import Select from 'components/Select/Select'
import { Input } from 'components/Input'
import { UserArchivePopup } from 'pages/CRUDUser/UserArchivePopup'
import { Button, BUTTON_TYPES } from 'components/Button'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { IconFullName } from 'features/HealthSystemDetails/IconFullName'
import {
  fetchCommunitiesWithHs,
  fetchDepartments,
  fetchHospitals,
  fetchTimeZonesList
} from 'pages/CRUDUser/api'
import { getUser } from 'redux/store/user/getters'
import {
  formatMoney,
  formatName,
  formatPhoneNumber,
  getLocalAccessToken,
  // parserPhoneNumber,
  preventInitialToCorrectFormat,
  validateMessages
} from 'helper/common'
import { ACTIONS, ROLES, SUBJECTS } from 'features/Permission/constants'
import defineAbilityFor from 'features/Permission/ability'
import { getCSList } from 'features/VendorInfo/api'
import useRouter from 'hooks/useRouter'
import { fetchHealthSystemsList } from 'features/HealthSystems/api'
import { setLoading } from 'redux/store/common/slice'
import { routes } from 'router/Config/config.routes'
import {
  emptyStateUser,
  ROLES_NAMES,
  ROLES_OPTIONS_ALL,
  ROLES_OPTIONS_CA,
  ROLES_OPTIONS_COMMONS_CS,
  ROLES_OPTIONS_COMMONS_VP,
  ROLES_OPTIONS_COMMUNITY,
  ROLES_OPTIONS_CS,
  ROLES_OPTIONS_HOSPITALS,
  ROLES_OPTIONS_VENDOR_CS,
  ROLES_OPTIONS_VICE
} from 'constants/common'
import { BTN_TXT, LABELS, PLACEHOLDERS, TOOLTIP } from 'constants/txt'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import { ReactComponent as Delete24 } from 'assets/svg/Delete24.svg'
import { THealthSystem } from 'features/HealthSystems/types'
import { TListItem, TUserForm, TUserFormProps } from './forms.user.d'
import { TOption } from 'components/Select/types'
import './forms.chapterInfo.scss'
import './forms.user.scss'
import { Avatar } from 'components/Avatar/Avatar'
import { API_HOST, API_URL } from 'services/Fetcher'
import axios from 'axios'
import { ReactComponent as DropPhotoIcon } from 'assets/svg/DropPhoto.svg'
import Typography from 'components/Typography/Typography'
import { Switch } from '../Switch'
import { getIsLoading } from '../../redux/store/common/getters'
import { ReactComponent as Information } from 'assets/svg/Information.svg'
import { notification } from '../Notification'
import { Popup } from '../Popup'
import usePopup from '../../hooks/usePopup'
import { Can } from 'features/Permission'
import { useParams } from 'react-router-dom'
import { useShowPopup } from '../../hooks/useShowPopup'
import { getCommunityInfo } from '../../redux/store/chapterInfo/getters'
import { TTimezonesItem } from '../../pages/CRUDUser/types'

const UserAvatar = ({ url, name }: { url: string; name: string }) => {
  return (
    <>
      <div className="user-form_avatar_hover-cover">
        <DropPhotoIcon />
        <Typography.Button>Upload</Typography.Button>
      </div>
      <Avatar
        className="user-form_avatar-icon"
        name={url ? '' : name || ''}
        src={!!url ? url : null}
        gap={5}
        style={{ height: '120px', width: '120px' }}
      />
    </>
  )
}

const getBase64 = (img, callback) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export const User = ({
  className,
  onSubmit,
  initialState,
  hsHosUser,
  hsUser,
  isProfile,
  setActiveTab,
  setIsActive,
  isActive,
  contractCategoriesList
}: TUserFormProps): ReactElement => {
  const [departments, setDepartments] = useState<TListItem[]>(
    initialState?.departments || []
  )
  const [hospitalsList, setHospitalsList] = useState<TListItem[]>([])
  const [communitiesList, setCommunitiesList] = useState<TListItem[]>([])
  const [timezonesList, setTimezonesList] = useState<TTimezonesItem[]>([])
  const [CSsList, setCSList] = useState<TListItem[]>([])
  const [error, setError] = useState({} as TUserForm)
  const [healthSystems, setHealthSystems] = useState<THealthSystem[]>([])
  const discardChangesPopup = usePopup()
  const [form] = Form.useForm()
  const inputRole = Form.useWatch('role', form)
  const healthSystem = Form.useWatch('health_system', form)
  const hospital = Form.useWatch('hospital', form)
  const hospitalsValue = Form.useWatch('hospital', form)
  const hospitalsField = Form.useWatch('hospitals', form)
  const communitiesFiled = Form.useWatch('communities', form)
  const hospitalOption = Form.useWatch(['hospitals', 0, 'hospital'], form)
  const communityOption = Form.useWatch(['communities', 0, 'community'], form)
  const contractStewardsNumber = Form.useWatch(
    'contract_stewards',
    form
  )?.filter((i) => i?.contract_steward)?.length
  const selectedHospitals = Form.useWatch('hospitals', form)?.filter(
    (i) => i?.hospital
  )
  const selectedCommunities = Form.useWatch('communities', form)?.filter(
    (i) => i?.community
  )
  const contractStewards = Form.useWatch(
    ['contract_stewards', 0, 'contract_steward'],
    form
  )
  const phoneNumber = Form.useWatch('phone_number', form)
  const contractStewardsAll = Form.useWatch('contract_stewards', form)
  const [role, setRole] = useState(inputRole || initialState?.role)
  const [hospitals, setHospitals] = useState([{}])
  const user = useSelector(getUser)
  const communityInfo = useSelector(getCommunityInfo)
  const dispatch = useDispatch()
  const loading = useSelector(getIsLoading)
  const { push, location } = useRouter()
  const { id } = useParams()
  const [avatarPreview, setAvatarPreview] = useState(initialState?.avatar)
  const [showPopup, confirmNavigation, cancelNavigation] = useShowPopup(
    form.isFieldsTouched()
  )
  const contractCategories = contractCategoriesList || []
  useEffect(() => {
    if (!showPopup && !id) setActiveTab('0')
  }, [showPopup])

  useEffect(() => {
    if (user.community || user.vendor || user.commons) {
      getSelectorsData()
      if (user.vendor) {
        getCSList(user.vendor as string).then((data) =>
          setCSList(data?.data?.results)
        )
      }
    }
  }, [user.community, user.vendor])

  useEffect(() => {
    form.setFieldsValue({
      hospitals: [
        {
          departments: undefined,
          hospital: hospitalsValue?.[hospitalsValue - 1]?.value
        }
      ]
    })
  }, [hospitalsValue])
  useEffect(() => {
    setRole(inputRole)
  }, [inputRole])

  useEffect(() => {
    initialState?.role && setRole(initialState?.role)
    if (initialState?.community_uuid && initialState?.health_system) {
      fetchHospitals(
        initialState?.community_uuid,
        initialState?.health_system
      ).then((data) => setHospitalsList(data?.data?.results))
      fetchDepartments(
        initialState?.community_uuid,
        initialState?.health_system
      ).then((data) => setDepartments(data?.data?.results))
    }

    isProfile
      ? form.resetFields(['hospitals', 'contract_stewards'])
      : form.resetFields()
    // setIsActive(false)
  }, [initialState])

  const getHospitalsDepartments = async (getHs?) => {
    const hs = form.getFieldValue(['health_system']) || getHs
    const hospital =
      form.getFieldValue('hospital') ||
      form.getFieldValue(['hospitals', 0, 'hospital'])
    if (user?.community && hs) {
      await fetchHospitals(user?.community, hs).then((data) => {
        setHospitalsList(data?.data?.results)
      })
      await fetchDepartments(user?.community, hs, hospital).then((data) =>
        setDepartments(data?.data?.results)
      )
    }
  }
  const getCommunitisWithHs = async () => {
    if (user?.commons) {
      await fetchCommunitiesWithHs().then((data) => {
        setCommunitiesList(data?.data?.results)
      })
    }
  }

  const getTimezonesList = async () => {
    await fetchTimeZonesList().then((data) => {
      setTimezonesList(data?.data)
    })
  }

  const getSelectorsData = async () => {
    if (!loading) {
      dispatch(setLoading(true))
    }
    try {
      if (user.community) {
        fetchHealthSystemsList(user.community).then((resp) => {
          if (!resp.data?.results) {
            return
          }

          setHealthSystems(resp.data.results)
        })
      }
      if (initialState?.health_system) {
        getHospitalsDepartments(initialState?.health_system)
      }
      if (
        initialState?.communities ||
        role === ROLES.COMMONS_CLINICAL_COORDINATOR
      ) {
        getCommunitisWithHs()
      }
      if (isProfile) {
        getTimezonesList()
      }
    } finally {
      dispatch(setLoading(false))
    }
  }

  const returnSelectOptionsCC = (arr: any[], index): TOption[] => {
    const cSUuid = contractStewardsAll?.[index]?.contract_steward

    const contract_categories = arr?.find(
      (i) => i.uuid === (cSUuid || contractStewards)
    )?.contract_categories

    if (contract_categories?.length) {
      return contract_categories?.map((j) => ({
        label: j?.name,
        value: j?.uuid
      }))
    }
    return []
  }

  const returnSelectOptions = (arr: TListItem[]): TOption[] => {
    if (arr?.length) {
      return arr?.map((item) => ({ label: item?.name, value: item?.uuid }))
    }
    return []
  }
  const returnSelectHospitalsOptions = (arr: TListItem[], index): TOption[] => {
    if (arr?.length) {
      return (
        arr
          ?.filter(
            (item) =>
              !(
                selectedHospitals?.find((i) => i?.hospital === item.uuid) &&
                selectedHospitals?.[index]?.hospital !== item.uuid
              )
          )
          ?.map((item) => ({ label: item?.name, value: item?.uuid })) ?? []
      )
    }
    return []
  }
  const returnSelectCommunitiesOptions = (
    arr: TListItem[],
    index
  ): TOption[] => {
    if (arr?.length) {
      return (
        arr
          ?.filter(
            (item) =>
              !(
                selectedCommunities?.find((i) => i?.community === item.uuid) &&
                selectedCommunities?.[index]?.community !== item.uuid
              )
          )
          ?.map((item) => ({ label: item?.name, value: item?.uuid })) ?? []
      )
    }
    return []
  }

  const returnSelectOptionsDepartments = (arr: any[], index): TOption[] => {
    if (arr?.length && selectedHospitals?.length) {
      const deps: any =
        arr?.find(
          (i) => i?.uuid === (selectedHospitals?.[index]?.hospital || '')
        )?.departments || []
      return deps?.map((item) => ({ label: item?.name, value: item?.uuid }))
    }
    return []
  }
  const returnSelectHsOptionsFromCommunity = (arr: any[], index): TOption[] => {
    if (arr?.length && selectedCommunities?.length) {
      const hs: any =
        arr?.find(
          (i) => i?.uuid === (selectedCommunities?.[index]?.community || '')
        )?.health_systems || []
      return hs?.map((item) => ({ label: item?.name, value: item?.uuid }))
    }
    return []
  }

  const returnSelectOptionsCS = (arr: any[], index): TOption[] => {
    if (arr?.length) {
      return arr
        ?.filter(
          (item) =>
            !(
              contractStewardsAll?.find(
                (i) => i?.contract_steward === item.uuid
              ) && contractStewardsAll?.[index]?.contract_steward !== item.uuid
            )
        )
        .map((item) => ({
          label: `${item?.first_name} ${item?.last_name}`,
          value: item?.uuid
        }))
    }
    return []
  }

  const cancelDiscardChanges = () => {
    discardChangesPopup.actions.close()
  }

  const submitCancellation = () => {
    isProfile && form.getFieldValue(['contract_categories'])
      ? form.resetFields(['phone_number', 'first_name', 'last_name', 'avatar'])
      : form.resetFields()
    discardChangesPopup.actions.close()
    setAvatarPreview(initialState?.avatar)

    setIsActive(false)
  }
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState<boolean>(false)

  const resetFields = () => {
    if (isActive) {
      discardChangesPopup.actions.open()
    } else {
      submitCancellation()
    }
  }

  const onSubmitForm = (values) => {
    dispatch(setLoading(true))
    values?.avatar === initialState?.avatar && delete values?.avatar
    if (values?.phone_number) {
      values.phone_number = !values?.phone_number.toString().includes('+1')
        ? '+1' + values.phone_number
        : values.phone_number
    } else delete values?.phone_number
    onSubmit(values)
      .then(() => {
        setError({} as TUserForm)
        setIsActive(false)
        if (isProfile) {
          dispatch(setLoading(false))
        } else {
          push(routes.myCommunity, { state: location.state })
        }
      })
      .catch((e) => {
        setError(e.data)
        dispatch(setLoading(false))
      })
  }

  const healthSystemSelect = () => (
    <Select
      options={returnSelectOptions(healthSystems)}
      propsItem={{
        label: LABELS.HEALTH_SYSTEM,
        name: 'health_system'
      }}
      propsSelect={{
        placeholder: PLACEHOLDERS.PLEASE_SELECT,
        disabled:
          !!hsHosUser ||
          !!hsUser ||
          isProfile ||
          user.uuid === initialState?.uuid ||
          defineAbilityFor(role, user, initialState?.uuid).can(
            ACTIONS.EDIT,
            SUBJECTS.FORM_ITEM
          ) ||
          (!isProfile &&
            defineAbilityFor(role, user, initialState?.uuid).can(
              ACTIONS.VIEW,
              SUBJECTS.FORM_ITEM
            )) ||
          defineAbilityFor(role, user, initialState?.uuid).can(
            ACTIONS.DISABLED,
            SUBJECTS.FORM_HEALTH_SYSTEM_INPUT
          ),
        loading: !healthSystems?.length && !isProfile,
        onChange: () => {
          getHospitalsDepartments()
          form.resetFields(['hospitals'])
          if (role === ROLES.HOSPITAL_ADMIN) {
            form.setFieldsValue({ hospital: '', hospitals: '' })
          }
          form.resetFields(['departments'])
        }
      }}
    />
  )

  const returnHospitals = (key, name, restField, remove, numberInQueue) => {
    const disableHandler = () => {
      if (hsHosUser) {
        return hospitals.length <= 1
      } else {
        return false
      }
    }
    return (
      <>
        <Space
          key={key}
          className="user-form_input-wide hospitals-wrapper"
          direction="vertical"
        >
          <div
            className={cn('user-form_hospitals_header', {
              disabled: disableHandler()
            })}
          >
            <div className="user-form_title">Hospital {name + 1}</div>
            <Tooltip
              title={TOOLTIP.AT_LEAST_ONE_HOSPITAL_SHOULD_BE_ASSIGNED}
              overlayInnerStyle={{
                display:
                  disableHandler() &&
                  defineAbilityFor(role, user, initialState?.uuid).can(
                    ACTIONS.EDIT,
                    SUBJECTS.FORM_HOSPITAL_LIST
                  )
                    ? 'block'
                    : 'none'
              }}
              placement="topRight"
            >
              {defineAbilityFor(role, user, initialState?.uuid).can(
                ACTIONS.EDIT,
                SUBJECTS.FORM_HOSPITAL_LIST
              ) && (
                <Button.Icon
                  className="user-form_delete-btn"
                  type={BUTTON_TYPES.LINK}
                  icon={<Delete24 />}
                  onClick={() => {
                    if (disableHandler()) return
                    remove(name)
                  }}
                  danger
                />
              )}
            </Tooltip>
          </div>
          <div className="user-form_hospitals_selects">
            <Select
              options={returnSelectHospitalsOptions(
                hospitalsList,
                numberInQueue
              )}
              propsItem={{
                ...restField,
                label: LABELS.HOSPITAL,
                name: [name, 'hospital'],
                rules: [{ required: !!hsHosUser }]
              }}
              propsSelect={{
                disabled:
                  isProfile ||
                  !defineAbilityFor(role, user, initialState?.uuid).can(
                    ACTIONS.EDIT,
                    SUBJECTS.FORM_HOSPITAL_LIST
                  ),
                loading: !hospitalsList?.length && !isProfile,
                placeholder: PLACEHOLDERS.PLEASE_SELECT,
                onChange: (value) => {
                  form.setFieldsValue({
                    hospitals: hospitalsField.map((i, idx) =>
                      idx === numberInQueue
                        ? {
                            hospital: value,
                            departments: [],
                            contract_categories: []
                          }
                        : i
                    )
                  })
                }
              }}
            />
            <Select.Multi
              options={returnSelectOptionsDepartments(
                hospitalsList,
                numberInQueue
              )}
              propsItem={{
                ...restField,
                label: LABELS.DEPARTMENTS,
                name: [name, 'departments']
              }}
              propsSelect={{
                placeholder: PLACEHOLDERS.PLEASE_SELECT,
                disabled:
                  isProfile ||
                  !hospitalOption ||
                  selectedHospitals?.length < numberInQueue + 1 ||
                  !defineAbilityFor(role, user, initialState?.uuid).can(
                    ACTIONS.EDIT,
                    SUBJECTS.FORM_HOSPITAL_LIST
                  ),
                loading: !departments?.length && !isProfile
              }}
            />
          </div>
          <div className="user-form_input-wide user-form_hospitals-last">
            <Select.Multi
              options={returnSelectOptions(contractCategories as TListItem[])}
              propsItem={{
                ...restField,
                label: LABELS.CONTRACT_CATEGORIES,
                name: [name, 'contract_categories']
              }}
              propsSelect={{
                placeholder: PLACEHOLDERS.PLEASE_SELECT,
                disabled:
                  isProfile ||
                  !hospitalOption ||
                  selectedHospitals?.length < numberInQueue + 1 ||
                  !defineAbilityFor(role, user, initialState?.uuid).can(
                    ACTIONS.EDIT,
                    SUBJECTS.FORM_HOSPITAL_LIST
                  )
              }}
            />
          </div>
        </Space>
      </>
    )
  }
  const returnCommunitiesWithHs = (
    key,
    name,
    restField,
    remove,
    numberInQueue
  ) => {
    return (
      <>
        <Space
          key={key}
          className="user-form_input-wide hospitals-wrapper"
          direction="vertical"
        >
          <div className={cn('user-form_hospitals_header')}>
            <div className="user-form_title">Community {name + 1}</div>
            {defineAbilityFor(role, user, initialState?.uuid).can(
              ACTIONS.EDIT,
              SUBJECTS.FORM_COMMUNITIES_WITH_HS_LIST
            ) && (
              <Button.Icon
                className="user-form_delete-btn"
                type={BUTTON_TYPES.LINK}
                icon={<Delete24 />}
                onClick={() => {
                  remove(name)
                }}
                danger
              />
            )}
          </div>
          <div className="user-form_hospitals_selects">
            <Select
              options={returnSelectCommunitiesOptions(
                communitiesList,
                numberInQueue
              )}
              propsItem={{
                ...restField,
                label: LABELS.COMMUNITY,
                name: [name, 'community'],
                rules: [{ required: !!hsHosUser }],
                help: error?.communities
                  ? error?.communities?.[name]?.community
                  : undefined,
                validateStatus: !!error?.communities?.[name]?.community
                  ? 'error'
                  : undefined
              }}
              propsSelect={{
                disabled:
                  isProfile ||
                  !defineAbilityFor(role, user, initialState?.uuid).can(
                    ACTIONS.EDIT,
                    SUBJECTS.FORM_COMMUNITIES_WITH_HS_LIST
                  ),
                loading: !communitiesList?.length && !isProfile,
                placeholder: PLACEHOLDERS.PLEASE_SELECT,
                onChange: (value) => {
                  form.setFieldsValue({
                    communities: communitiesFiled.map((i, idx) =>
                      idx === numberInQueue
                        ? {
                            community: value,
                            health_systems: []
                          }
                        : i
                    )
                  })
                }
              }}
            />
            <Select.Multi
              options={returnSelectHsOptionsFromCommunity(
                communitiesList,
                numberInQueue
              )}
              propsItem={{
                ...restField,
                label: LABELS.HEALTH_SYSTEMS,
                name: [name, 'health_systems'],
                help: error?.communities
                  ? error?.communities?.[name]?.health_systems
                  : undefined,
                validateStatus: !!error?.communities?.[name]?.health_systems
                  ? 'error'
                  : undefined
              }}
              propsSelect={{
                placeholder: PLACEHOLDERS.PLEASE_SELECT,
                disabled:
                  isProfile ||
                  !communityOption ||
                  selectedCommunities?.length < numberInQueue + 1 ||
                  !defineAbilityFor(role, user, initialState?.uuid).can(
                    ACTIONS.EDIT,
                    SUBJECTS.FORM_COMMUNITIES_WITH_HS_LIST
                  ),
                loading: !communityOption?.length && !isProfile
              }}
            />
          </div>
        </Space>
      </>
    )
  }

  const returnCS = (key, name, restField, remove, numberInQueue) => {
    return (
      <>
        <Space key={key} className="user-form_input-wide" direction="vertical">
          <div className="user-form_hospitals_header">
            <div className="user-form_title">
              Contract Steward {numberInQueue + 1}
            </div>
            {!defineAbilityFor(role, user, initialState?.uuid).can(
              ACTIONS.VIEW,
              SUBJECTS.CONTRACT_STEWARDS_LIST_BUTTON
            ) && (
              <Button.Icon
                className="user-form_delete-btn"
                icon={<Delete24 />}
                type={BUTTON_TYPES.LINK}
                onClick={() => remove(name)}
              />
            )}
          </div>
          <div className="user-form_cs">
            <Select
              options={returnSelectOptionsCS(CSsList, numberInQueue)}
              propsItem={{
                ...restField,
                label: LABELS.ASSIGN_TO_CONTRACT_STEWARD,
                name: [name, 'contract_steward']
              }}
              propsSelect={{
                placeholder: PLACEHOLDERS.PLEASE_SELECT,
                disabled:
                  isProfile ||
                  defineAbilityFor(role, user, initialState?.uuid).can(
                    ACTIONS.EDIT,
                    SUBJECTS.FORM_ITEM
                  ),

                loading: !CSsList?.length && !isProfile
              }}
            />
          </div>
          <div className="user-form_input-wide user-form_hospitals-last">
            <Select.Multi
              options={returnSelectOptionsCC(CSsList, numberInQueue)}
              propsItem={{
                ...restField,
                label: LABELS.CONTRACT_CATEGORIES,
                name: [name, 'contract_categories'],
                rules: [
                  {
                    required:
                      !!contractStewardsAll?.[numberInQueue]?.contract_steward
                  }
                ]
              }}
              propsSelect={{
                placeholder: PLACEHOLDERS.PLEASE_SELECT,
                disabled:
                  isProfile ||
                  contractStewardsNumber < numberInQueue + 1 ||
                  defineAbilityFor(role, user, initialState?.uuid).can(
                    ACTIONS.EDIT,
                    SUBJECTS.FORM_ITEM
                  )
              }}
            />
          </div>
        </Space>
      </>
    )
  }

  const returnHospitalsInitialValues = () => {
    if (initialState?.hospitals?.length) {
      return {
        hospitals: initialState?.hospitals.map((item) => {
          return {
            hospital: {
              value: item?.hospital?.uuid,
              label: item?.hospital?.name
            },
            contract_categories: item?.contract_categories.map((contract) => ({
              value: contract.uuid,
              label: contract.name
            })),
            departments: item?.departments.map((department) => ({
              value: department.uuid,
              label: department.name
            }))
          }
        })
      }
    }
    return null as any
  }
  const returnCommunitiesInitialValues = () => {
    if (initialState?.communities?.length) {
      return {
        communities: initialState?.communities?.map((item) => {
          return {
            community: {
              value: item?.community,
              label: item?.name
            },
            health_systems: item?.health_systems?.map((hs) => ({
              value: hs.uuid,
              label: hs.name
            }))
          }
        })
      }
    }
    return null as any
  }

  const returnCSInitialValues = () => {
    if (initialState?.contract_stewards?.length) {
      return {
        contarct_stewards: initialState?.contract_stewards.map((item) => {
          return {
            contarct_steward: {
              value: item?.contarct_steward?.uuid,
              label: `${item?.contarct_steward?.first_name} ${item?.contarct_steward?.last_name}`
            },
            contract_categories: item?.contract_categories.map((contract) => ({
              value: contract.uuid,
              label: contract.name
            }))
          }
        })
      }
    }
    return null as any
  }

  const returnListOfAvailableRoles = useMemo(() => {
    if (hsHosUser) {
      return ROLES_OPTIONS_HOSPITALS
    }
    if (
      !isProfile &&
      defineAbilityFor(role, user).can(ACTIONS.EDIT, SUBJECTS.FORM_ITEM)
    ) {
      return ROLES_OPTIONS_ALL
    }

    switch (user.role) {
      case 'community_president':
        return ROLES_OPTIONS_COMMUNITY
      case 'community_vice_president':
        return ROLES_OPTIONS_VICE
      case 'vendor_contract_admin':
        return ROLES_OPTIONS_CA
      case 'vendor_contract_steward':
        return ROLES_OPTIONS_VENDOR_CS
      case 'commons_vice_president':
        return ROLES_OPTIONS_COMMONS_VP
      case 'commons_contract_steward':
        return ROLES_OPTIONS_COMMONS_CS
      default:
        return ROLES_OPTIONS_CS
    }
  }, [user.role, hsHosUser])

  const returnListOfAvailableTZ = useMemo(() => {
    return timezonesList.map((zone) => ({
      label: zone.value,
      value: zone.id
    }))
  }, [timezonesList])

  const firstName = Form.useWatch('first_name', form)
  const lastName = Form.useWatch('last_name', form)
  const [name, setName] = useState(
    `${firstName?.charAt(0)}${lastName?.charAt(0)}`.toUpperCase()
  )

  useEffect(() => {
    if (role === ROLES.COMMONS_CLINICAL_COORDINATOR) {
      getCommunitisWithHs()
    }
  }, [role])

  const handleChange = (info) => {
    if (info?.file?.status === 'uploading') {
      setLoading(true)
      return
    }
    if (info?.file?.status === 'done') {
      getBase64(info.file.originFileObj, () => {
        setLoading(false)
      })
    }
  }
  useEffect(() => {
    setName(
      `${firstName?.charAt(0) || ''}${lastName?.charAt(0) || ''}`.toUpperCase()
    )
  }, [firstName, lastName])

  const canEditAdditionalInfo = useMemo(() => {
    return (
      isProfile &&
      defineAbilityFor(role, user, initialState?.uuid).can(
        ACTIONS.CRUD,
        SUBJECTS.ADDITIONAL_INFO_PROFILE
      )
    )
  }, [isProfile, role, user, initialState])

  const avatarImageRequest = async (options) => {
    const { onError, file } = options
    const fmData = new FormData()
    const token = getLocalAccessToken()
    fmData.append('file', file)
    dispatch(setLoading(true))
    try {
      const res = await axios.post(
        `${API_HOST}${API_URL}files/profile_image/`,
        fmData,
        { headers: { authorization: `Bearer ${token}` } }
      )
      if (res) {
        setIsActive(true)
        setAvatarPreview(res?.data?.file)
        form?.setFieldsValue({ avatar: res?.data?.uuid })
      }
    } catch (err) {
      const error = new Error('Some error')
      console.error('Error: ', err, error)
      onError({ err })
      const message = (err as any).response.data.file.file
      notification.error({ message })
      form?.setFieldsValue({ avatar: initialState?.avatar })
    } finally {
      dispatch(setLoading(false))
    }
  }

  const imageUrl = Form.useWatch('avatar', form)
  return (
    <>
      {!loading && (
        <Form<TUserForm>
          validateMessages={validateMessages}
          form={form}
          className={cn('user-form_container', className, {
            'mt-24':
              !!initialState?.contract_categories_waiting_for_approval?.length
          })}
          layout="vertical"
          onFinish={onSubmitForm}
          initialValues={
            preventInitialToCorrectFormat(initialState) || emptyStateUser
          }
          onFieldsChange={() => setIsActive(true)}
          onValuesChange={(_, all) => {
            !!hsHosUser && all.hospitals && setHospitals(all.hospitals)
            all?.phone_number?.toString().length !== 13
              ? setIsPhoneNumberValid(true)
              : setIsPhoneNumberValid(false)
          }}
        >
          <div className="user-form_avatar">
            <Form.Item name="avatar">
              {defineAbilityFor(role, user, initialState?.uuid).can(
                ACTIONS.UPLOAD,
                SUBJECTS.AVATAR
              ) ? (
                <Input.File
                  imageUrl={imageUrl || ''}
                  displayedBlock={
                    <UserAvatar url={avatarPreview || imageUrl} name={name} />
                  }
                  onChange={handleChange}
                  customRequest={avatarImageRequest}
                  capture={false}
                />
              ) : (
                <Avatar
                  className="user-form_avatar-icon"
                  name={avatarPreview || imageUrl ? '' : name || ''}
                  src={
                    !!avatarPreview || imageUrl
                      ? avatarPreview || imageUrl
                      : null
                  }
                />
              )}
            </Form.Item>
          </div>
          <div className="user-form">
            <Input
              propsItem={{
                label: LABELS.FIRST_NAME,
                name: 'first_name',
                help: error?.first_name ? error?.first_name[0] : undefined,
                validateStatus: error?.first_name ? 'error' : undefined,
                rules: [
                  {
                    pattern: /^[-\w\s]+$/,
                    max: 40,
                    required: true
                  }
                ],
                normalize: formatName
              }}
              propsInput={{
                disabled:
                  defineAbilityFor(role, user, initialState?.uuid).can(
                    ACTIONS.EDIT,
                    SUBJECTS.FORM_ITEM
                  ) ||
                  (!isProfile &&
                    defineAbilityFor(role, user, initialState?.uuid).can(
                      ACTIONS.VIEW,
                      SUBJECTS.FORM_ITEM
                    )),
                showCount: true,
                maxLength: 40
              }}
            />
            <Input
              propsItem={{
                label: LABELS.LAST_NAME,
                name: 'last_name',
                help: error?.last_name ? error?.last_name[0] : undefined,
                validateStatus: error?.last_name ? 'error' : undefined,
                rules: [
                  {
                    pattern: /^[-\w\s]+$/,
                    max: 40,
                    required: true
                  }
                ]
              }}
              propsInput={{
                disabled:
                  defineAbilityFor(role, user, initialState?.uuid).can(
                    ACTIONS.EDIT,
                    SUBJECTS.FORM_ITEM
                  ) ||
                  (!isProfile &&
                    defineAbilityFor(role, user, initialState?.uuid).can(
                      ACTIONS.VIEW,
                      SUBJECTS.FORM_ITEM
                    )),
                showCount: true,
                maxLength: 40
              }}
            />
            <Input
              propsItem={{
                label: LABELS.EMAIL,
                name: 'email',
                help: error?.email ? error?.email[0] : undefined,
                validateStatus: error?.email ? 'error' : undefined,
                validateTrigger: 'onBlur',
                rules: [
                  {
                    required: true,
                    type: 'email',
                    validateTrigger: 'onSubmit'
                  }
                ]
              }}
              propsInput={{
                placeholder: PLACEHOLDERS.EMAIL,
                disabled:
                  isProfile ||
                  user.uuid === initialState?.uuid ||
                  defineAbilityFor(role, user, initialState?.uuid).can(
                    ACTIONS.EDIT,
                    SUBJECTS.FORM_ITEM
                  ) ||
                  (!isProfile &&
                    defineAbilityFor(role, user, initialState?.uuid).can(
                      ACTIONS.VIEW,
                      SUBJECTS.FORM_ITEM
                    ))
              }}
            />
            {id !== 'new' && (
              <div className="column">
                <Input.Phone
                  propsItem={{
                    label: LABELS.PHONE_NUMBER,
                    name: 'phone_number',
                    help: error?.phone_number
                      ? error?.phone_number[0]
                      : undefined,
                    validateStatus: error?.phone_number ? 'error' : undefined,
                    rules: [
                      {
                        required: id === user?.uuid,
                        pattern: /^[0-9]{10}$/,
                        validateTrigger: isPhoneNumberValid
                          ? ['onSubmit']
                          : ['onBlur']
                      }
                    ]
                  }}
                  propsInputNumber={{
                    disabled: true,
                    prefix: phoneNumber ? '+1' : '',
                    controls: false,
                    formatter: formatPhoneNumber
                  }}
                />
                {defineAbilityFor(role, user, initialState?.uuid).can(
                  ACTIONS.EDIT,
                  SUBJECTS.HIDE_PHONE_NUMBER
                ) && (
                  <div className="user-form__switch row justify-end align-center mt-8 gap-12">
                    <Form.Item
                      name="hide_phone_number_for_vendor"
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>
                    <Typography.Body1>Hide for Vendors</Typography.Body1>
                  </div>
                )}
              </div>
            )}
            {isProfile &&
              defineAbilityFor(role, user).can(
                ACTIONS.VIEW,
                SUBJECTS.CONTACT_PERSON
              ) && (
                <div
                  className={cn('user-form_contact-person', {
                    commons: user.commons
                  })}
                >
                  <IconFullName
                    className="user-form_community"
                    title="Contact person"
                    firstName={
                      communityInfo?.contact_person?.first_name ||
                      user?.contact_person?.first_name
                    }
                    lastName={
                      communityInfo?.contact_person?.last_name ||
                      user?.contact_person?.last_name
                    }
                    roleName={
                      ROLES_NAMES[communityInfo?.contact_person?.role] ||
                      (!!user?.contact_person
                        ? ROLES_NAMES[user?.contact_person.role]
                        : '')
                    }
                    src={
                      communityInfo?.contact_person?.avatar?.file_preview ||
                      user?.contact_person?.avatar?.file_preview
                    }
                    uuid={
                      communityInfo?.contact_person?.uuid ||
                      user?.contact_person?.uuid
                    }
                  />
                </div>
              )}
            {isProfile &&
              defineAbilityFor(role, user).can(
                ACTIONS.VIEW,
                SUBJECTS.FORM_COMMUNITY_INPUT
              ) && (
                <div className="user-form_input-wide">
                  <Input
                    propsItem={{
                      label: LABELS.COMMUNITY_NAME,
                      name: 'community',
                      help: error?.community ? error?.community[0] : undefined,
                      validateStatus: error?.community ? 'error' : undefined,
                      rules: [
                        {
                          max: 100,
                          required: true
                        }
                      ]
                    }}
                    propsInput={{
                      disabled: isProfile,
                      showCount: true,
                      maxLength: 100
                    }}
                  />
                </div>
              )}
            {defineAbilityFor(role, user).can(
              ACTIONS.VIEW,
              SUBJECTS.ADDITIONAL_INFO_PROFILE
            ) &&
              id !== 'new' && (
                <>
                  <Input
                    propsItem={{
                      label: LABELS.TITLE,
                      name: 'title',
                      help: error?.title ? error?.title[0] : undefined,
                      validateStatus: error?.title ? 'error' : undefined,
                      rules: [
                        {
                          max: 50,
                          required: false
                        }
                      ]
                    }}
                    propsInput={{
                      disabled: !canEditAdditionalInfo,
                      showCount: true,
                      maxLength: 50
                    }}
                  />
                  <Input
                    propsItem={{
                      label: LABELS.YEARS_OF_SERVICE,
                      name: 'years_of_service',
                      help: error?.years_of_service
                        ? error?.years_of_service[0]
                        : undefined,
                      validateStatus: error?.years_of_service
                        ? 'error'
                        : undefined,
                      rules: [
                        {
                          max: 100,
                          required: false
                        }
                      ]
                    }}
                    propsInput={{
                      disabled: !canEditAdditionalInfo,
                      showCount: true,
                      maxLength: 100
                    }}
                  />
                  <div className="user-form_input-wide">
                    <Input
                      propsItem={{
                        label: LABELS.VENDOR_PREFERENCES,
                        name: 'vendor_preferences',
                        help: error?.vendor_preferences
                          ? error?.vendor_preferences[0]
                          : undefined,
                        validateStatus: error?.vendor_preferences
                          ? 'error'
                          : undefined,
                        rules: [
                          {
                            max: 100,
                            required: false
                          }
                        ]
                      }}
                      propsInput={{
                        disabled: !canEditAdditionalInfo,
                        showCount: true,
                        maxLength: 100
                      }}
                    />
                  </div>
                  <div className="user-form_input-wide">
                    <Input
                      propsItem={{
                        label: LABELS.HOBBIES,
                        name: 'hobbies',
                        help: error?.hobbies ? error?.hobbies[0] : undefined,
                        validateStatus: error?.hobbies ? 'error' : undefined,
                        rules: [
                          {
                            max: 100,
                            required: false
                          }
                        ]
                      }}
                      propsInput={{
                        disabled: !canEditAdditionalInfo,
                        showCount: true,
                        maxLength: 100
                      }}
                    />
                  </div>
                </>
              )}
            <Select
              options={
                isProfile ? ROLES_OPTIONS_ALL : returnListOfAvailableRoles
              }
              propsItem={{
                label: LABELS.ROLE,
                name: 'role',
                help: error?.role ? error?.role[0] : undefined,
                validateStatus: error?.role ? 'error' : undefined,
                rules: [
                  {
                    required: true
                  }
                ]
              }}
              propsSelect={{
                placeholder: PLACEHOLDERS.PLEASE_SELECT,
                onChange: () =>
                  form.resetFields([
                    'contract_stewards',
                    'contract_categories',
                    'departments',
                    'target_amount',
                    'hospitals',
                    'hospital'
                  ]),
                disabled:
                  isProfile ||
                  user.uuid === initialState?.uuid ||
                  defineAbilityFor(role, user, initialState?.uuid).can(
                    ACTIONS.EDIT,
                    SUBJECTS.FORM_ITEM
                  ) ||
                  (!isProfile &&
                    defineAbilityFor(role, user, initialState?.uuid).can(
                      ACTIONS.VIEW,
                      SUBJECTS.FORM_ITEM
                    ))
              }}
            />
            {(!!hsHosUser || !!hsUser) && (
              <div className="user-form_health-system">
                {healthSystemSelect()}
              </div>
            )}
            {!hsHosUser &&
              !hsUser &&
              defineAbilityFor(role, user).can(
                ACTIONS.VIEW,
                SUBJECTS.FORM_HEALTH_SYSTEM_INPUT
              ) && (
                <div className="user-form_health-system">
                  {healthSystemSelect()}
                </div>
              )}
            {defineAbilityFor(role, user).can(
              ACTIONS.VIEW,
              SUBJECTS.FORM_HOSPITAL_INPUT
            ) && (
              <Select
                options={returnSelectOptions(hospitalsList)}
                className="user-form_hospital"
                propsItem={{
                  label: LABELS.HOSPITAL,
                  name: 'hospital',
                  rules: [
                    {
                      required: !!healthSystem,
                      validateTrigger: 'onSubmit'
                    }
                  ]
                }}
                propsSelect={{
                  onChange: () => {
                    getHospitalsDepartments()
                    form.resetFields(['departments'])
                    form.resetFields(['contract_categories'])
                  },
                  placeholder: PLACEHOLDERS.PLEASE_SELECT,
                  disabled:
                    isProfile ||
                    !healthSystem ||
                    defineAbilityFor(role, user).can(
                      ACTIONS.EDIT,
                      SUBJECTS.FORM_ITEM
                    ),
                  loading:
                    !hospitalsList?.length && (!isProfile || healthSystem)
                }}
              />
            )}
            {defineAbilityFor(role, user).can(
              ACTIONS.VIEW,
              SUBJECTS.FORM_DEPARTMENTS_INPUT
            ) && (
              <Select.Multi
                options={returnSelectOptions(departments as TListItem[])}
                propsItem={{
                  label: LABELS.DEPARTMENTS,
                  name: 'departments'
                }}
                propsSelect={{
                  placeholder: PLACEHOLDERS.PLEASE_SELECT,
                  disabled:
                    isProfile ||
                    !hospital ||
                    defineAbilityFor(role, user).can(
                      ACTIONS.EDIT,
                      SUBJECTS.FORM_ITEM
                    ),
                  loading: !departments?.length && (!isProfile || healthSystem)
                }}
              />
            )}
            {defineAbilityFor(role, user).can(
              ACTIONS.VIEW,
              SUBJECTS.FORM_CONTARCT_CATEGORIES_INPUT
            ) &&
              defineAbilityFor(role, user).cannot(
                ACTIONS.CRUD,
                SUBJECTS.APPROVE_PENDING_USERS
              ) && (
                <div className="user-form_input-wide">
                  <Select.Multi
                    options={returnSelectOptions(contractCategories)}
                    propsItem={{
                      label: LABELS.CONTRACT_CATEGORIES,
                      name: 'contract_categories'
                    }}
                    propsSelect={{
                      placeholder: PLACEHOLDERS.PLEASE_SELECT,
                      disabled:
                        isProfile ||
                        ((role === ROLES.INFLUENCER ||
                          role === ROLES.STAKEHOLDER) &&
                          !hospital) ||
                        user.uuid === initialState?.uuid ||
                        defineAbilityFor(role, user, initialState?.uuid).can(
                          ACTIONS.EDIT,
                          SUBJECTS.FORM_ITEM
                        ) ||
                        (!isProfile &&
                          defineAbilityFor(role, user, initialState?.uuid).can(
                            ACTIONS.VIEW,
                            SUBJECTS.FORM_ITEM
                          )),
                      loading:
                        !contractCategories?.length &&
                        (!isProfile || healthSystem)
                    }}
                  />
                </div>
              )}
            {defineAbilityFor(role, user, initialState?.uuid).can(
              ACTIONS.VIEW,
              SUBJECTS.FORM_HOSPITAL_LIST
            ) && (
              <Form.List
                name="hospitals"
                initialValue={returnHospitalsInitialValues()}
              >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }, id) =>
                      returnHospitals(key, name, restField, remove, id)
                    )}
                    {defineAbilityFor(role, user).can(
                      ACTIONS.EDIT,
                      SUBJECTS.FORM_ITEM
                    ) || (
                      <Button
                        type={BUTTON_TYPES.GHOST}
                        icon={<Plus16 />}
                        className="user-form_add-hospital"
                        onClick={() => add()}
                        disabled={
                          !healthSystem ||
                          selectedHospitals?.length !== hospitalsField?.length
                        }
                        upperCase
                      >
                        {BTN_TXT.ADD_HOSPITAL}
                      </Button>
                    )}
                  </>
                )}
              </Form.List>
            )}
            {defineAbilityFor(role, user, initialState?.uuid).can(
              ACTIONS.VIEW,
              SUBJECTS.FORM_COMMUNITIES_WITH_HS_LIST
            ) && (
              <Form.List
                name="communities"
                initialValue={returnCommunitiesInitialValues()}
              >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }, id) =>
                      returnCommunitiesWithHs(key, name, restField, remove, id)
                    )}
                    {defineAbilityFor(role, user).can(
                      ACTIONS.EDIT,
                      SUBJECTS.FORM_COMMUNITIES_WITH_HS_LIST
                    ) && (
                      <Button
                        type={BUTTON_TYPES.GHOST}
                        icon={<Plus16 />}
                        className="user-form_add-hospital"
                        onClick={() => add()}
                        disabled={
                          selectedCommunities?.length !==
                          communitiesFiled?.length
                        }
                        upperCase
                      >
                        {BTN_TXT.ADD_COMMUNITY}
                      </Button>
                    )}
                  </>
                )}
              </Form.List>
            )}
            {defineAbilityFor(role, user, initialState?.uuid).can(
              ACTIONS.VIEW,
              SUBJECTS.CONTRACT_STEWARDS_LIST
            ) && (
              <Form.List
                name="contract_stewards"
                initialValue={returnCSInitialValues()}
              >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }, id) =>
                      returnCS(key, name, restField, remove, id)
                    )}
                    {!defineAbilityFor(role, user, initialState?.uuid).can(
                      ACTIONS.VIEW,
                      SUBJECTS.CONTRACT_STEWARDS_LIST_BUTTON
                    ) && (
                      <Button
                        type={BUTTON_TYPES.GHOST}
                        icon={<Plus16 />}
                        className="user-form_add-hospital"
                        onClick={() => add()}
                        upperCase
                      >
                        {BTN_TXT.ASSIGN_TO_CONTRACT_STEWARD}
                      </Button>
                    )}
                  </>
                )}
              </Form.List>
            )}
            {defineAbilityFor(role, user).can(
              ACTIONS.VIEW,
              SUBJECTS.FORM_SAVINGS_GOAL
            ) && (
              <div className="user-form_goal">
                <Input.Number
                  propsItem={{
                    label: (
                      <div className="user-form_goal_label">
                        <div>Annual savings goal, $</div>
                        <Tooltip
                          title={
                            <div>
                              Annual savings are collected for the period from
                              Jan 1 till Dec 31. <br />
                              <br />
                              Current goal does not affect on previous years
                              results.
                            </div>
                          }
                          placement="topLeft"
                        >
                          <Information />
                        </Tooltip>
                      </div>
                    ),
                    name: 'target_amount',
                    help: error?.target_amount
                      ? error?.target_amount[0]
                      : undefined,
                    validateStatus: error?.target_amount ? 'error' : undefined,
                    rules: [
                      {
                        pattern: /^(\d*\.)?\d+$/
                      }
                    ]
                  }}
                  propsInputNumber={{
                    disabled:
                      defineAbilityFor(role, user, initialState?.uuid).can(
                        ACTIONS.EDIT,
                        SUBJECTS.FORM_SAVINGS_GOAL
                      ) ||
                      (!isProfile &&
                        defineAbilityFor(role, user, initialState?.uuid).can(
                          ACTIONS.EDIT,
                          SUBJECTS.FORM_ITEM
                        )),
                    controls: false,
                    defaultValue: 0,
                    formatter: formatMoney
                  }}
                />
              </div>
            )}
          </div>
          {isProfile && (
            <div className="user-form mt-24">
              <Select
                options={returnListOfAvailableTZ}
                propsItem={{
                  label: LABELS.TIME_ZONE,
                  name: 'timezone_id',
                  help: error?.timezone_id ? error?.timezone_id[0] : undefined,
                  validateStatus: error?.timezone_id ? 'error' : undefined,
                  rules: [
                    {
                      required: true
                    }
                  ]
                }}
                propsSelect={{
                  placeholder: PLACEHOLDERS.PLEASE_SELECT,
                  disabled: !returnListOfAvailableTZ.length
                }}
              />
            </div>
          )}
          <div className="user-form_buttons">
            <Can I={ACTIONS.ARCHIVE} a={SUBJECTS.USER}>
              {!isProfile && initialState?.uuid && (
                <UserArchivePopup<TUserForm> selected={initialState} />
              )}
            </Can>
            <div
              className={cn(
                'community-info-form_buttons',
                isActive || 'community-info-form_buttons_hide'
              )}
            >
              <Button
                onClick={resetFields}
                type={BUTTON_TYPES.DEFAULT}
                upperCase
              >
                {BTN_TXT.CANCEL}
              </Button>
              <Button type={BUTTON_TYPES.PRIMARY} htmlType="submit" upperCase>
                {hsHosUser || hsUser
                  ? BTN_TXT.CREATE_AND_ADD_TO_LIST
                  : !isProfile && !initialState?.uuid
                  ? BTN_TXT.CREATE
                  : BTN_TXT.SAVE}
              </Button>
            </div>
          </div>
        </Form>
      )}
      <Popup.Discard
        visible={showPopup ? showPopup : discardChangesPopup.state.visible}
        onCancel={showPopup ? cancelNavigation : cancelDiscardChanges}
        onSubmit={showPopup ? confirmNavigation : submitCancellation}
      />
    </>
  )
}
