import { useCallback, useMemo, useState } from 'react'
import { Button, BUTTON_TYPES } from 'components/Button'
import { BTN_TXT } from 'constants/txt'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import { RFP_ACCEPTANCE_STATUSES, RFP_STATUSES } from 'features/RFP/constants'
import { useRFPDetailsPopupContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsPopupProvider'
import { ChangeRFPResponsePopup } from './ChangeRFPResponsePopup/ChangeRFPResponsePopup'

const CAN_ACTION_IN_STATUSES = [
  RFP_STATUSES.BIDDING_IN_PROGRESS,
  RFP_STATUSES.BIDDING_QA,
  RFP_STATUSES.PRICE_FILES_ANALYSIS
] as ValueOfObject<typeof RFP_STATUSES>[]

const CAN_NOT_DECLINE_RFP_IN_STATUSES = [
  RFP_STATUSES.CREATED,
  RFP_STATUSES.ARCHIVED,
  RFP_STATUSES.CANCELED
] as unknown as ValueOfObject<typeof RFP_STATUSES>

export const VendorActions = () => {
  const { state, actions } = useRFPDetailsContext()
  const { biddingData, data, isVendor, isVendorCanManageRFP } = state
  const { changeRFPResponsePopup } = useRFPDetailsPopupContext()
  const { handleChangeRFPAcceptance } = actions
  const { open } = changeRFPResponsePopup.actions
  const handleChangeAcceptance = useCallback(
    (status: string) => {
      !!biddingData.acceptance_status
        ? open()
        : handleChangeRFPAcceptance(status)
    },
    [open, handleChangeRFPAcceptance, biddingData.acceptance_status]
  )
  const isActionsButtonVisible = useMemo(() => {
    return (
      data.status !== RFP_STATUSES.CANCELED &&
      data.status !== RFP_STATUSES.CREATED &&
      data.status !== RFP_STATUSES.ARCHIVED &&
      isVendorCanManageRFP
    )
  }, [data, isVendorCanManageRFP])

  const [changeStatusTo, setChangestatusTo] = useState<string>(
    RFP_ACCEPTANCE_STATUSES.ACCEPTED
  )

  const isVendorASOverdue =
    biddingData.acceptance_status === RFP_ACCEPTANCE_STATUSES.OVERDUE &&
    !!data.resend

  const isVendorNotAnswer = isVendor && !biddingData.acceptance_status

  const isVendorCanChangeTheDecision =
    data.status === RFP_STATUSES.VENDOR_ACCEPTANCE &&
    biddingData.acceptance_status !== RFP_ACCEPTANCE_STATUSES.OVERDUE

  const isVendorDeclined =
    biddingData.acceptance_status === RFP_ACCEPTANCE_STATUSES.DECLINED

  const isVendorDeclinedAndBidWasResent = isVendorDeclined && !!data.resend

  const canAccept = useMemo(() => {
    return (
      (isVendorNotAnswer &&
        (data.status === RFP_STATUSES.BIDDING_QA ||
          data.status === RFP_STATUSES.VENDOR_ACCEPTANCE)) ||
      (isVendorASOverdue && data.status === RFP_STATUSES.BIDDING_IN_PROGRESS) ||
      (isVendorDeclinedAndBidWasResent &&
        CAN_ACTION_IN_STATUSES.includes(data.status)) ||
      (isVendorDeclined && isVendorCanChangeTheDecision)
    )
  }, [
    isVendorNotAnswer,
    isVendorASOverdue,
    isVendorDeclined,
    isVendorDeclinedAndBidWasResent,
    data.status,
    isVendorCanChangeTheDecision
  ])

  // in no 'special' statuses (BIDDING_IN_PROGRESS, BIDDING_IN_QA, PRICE_FILES_ANALYSIS)
  // and not in status VENDOR_ACCEPTANCE where vendor can change the decision
  // vendor can still decline RFP
  const canDeclineRfpInOtherStatuses =
    !CAN_NOT_DECLINE_RFP_IN_STATUSES.includes(data.status) &&
    !CAN_ACTION_IN_STATUSES.includes(data.status) &&
    (data.status === RFP_STATUSES.VENDOR_ACCEPTANCE
      ? !biddingData.acceptance_status
      : true)

  const canDecline = useMemo(() => {
    return (
      (biddingData.acceptance_status === RFP_ACCEPTANCE_STATUSES.ACCEPTED &&
        (CAN_ACTION_IN_STATUSES.includes(data.status) ||
          isVendorCanChangeTheDecision)) ||
      (data.status === RFP_STATUSES.BIDDING_IN_PROGRESS &&
        biddingData.acceptance_status === RFP_ACCEPTANCE_STATUSES.OVERDUE &&
        !!data.resend) ||
      (isVendorNotAnswer && data.status === RFP_STATUSES.BIDDING_QA) ||
      canDeclineRfpInOtherStatuses
    )
  }, [
    isVendorCanChangeTheDecision,
    biddingData.acceptance_status,
    data.status,
    canDeclineRfpInOtherStatuses,
    isVendorNotAnswer
  ])

  return (
    <>
      {isActionsButtonVisible && (
        <>
          {canDecline && (
            <Button
              type={BUTTON_TYPES.DEFAULT}
              onClick={() => {
                handleChangeAcceptance(RFP_ACCEPTANCE_STATUSES.DECLINED)
                setChangestatusTo(RFP_ACCEPTANCE_STATUSES.DECLINED)
              }}
              danger
              upperCase
            >
              {BTN_TXT.DECLINE_RFP}
            </Button>
          )}
          {canAccept && (
            <Button
              type={
                !!biddingData.acceptance_status
                  ? BUTTON_TYPES.DEFAULT
                  : BUTTON_TYPES.PRIMARY
              }
              onClick={() => {
                handleChangeAcceptance(RFP_ACCEPTANCE_STATUSES.ACCEPTED)
                setChangestatusTo(RFP_ACCEPTANCE_STATUSES.ACCEPTED)
              }}
              upperCase
            >
              {BTN_TXT.ACCEPT_RFP}
            </Button>
          )}
          <ChangeRFPResponsePopup statusChangeTo={changeStatusTo} />
        </>
      )}
    </>
  )
}
