import { useState, useEffect, useMemo, FC } from 'react'
import cn from 'classnames'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ContractCategory } from './ContractCategory/ContractCategory'
import { Typography } from '../../components/Typography'
import { Button, BUTTON_TYPES } from '../../components/Button'
import { Popup } from '../../components/Popup'
import { Select } from '../../components/Select'
import { ACTIONS, Can, SUBJECTS } from '../Permission'
import { setLoading } from '../../redux/store/common/slice'
import usePopup from '../../hooks/usePopup'
import useRouter from '../../hooks/useRouter'
import { useShowPopup } from '../../hooks/useShowPopup'
import { approveContractCategories } from './api'
import { updateUser } from '../../pages/CRUDUser/api'
import { returnSelectOptions } from './utils'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import { BTN_TXT, LABELS, PLACEHOLDERS } from '../../constants'
import { routes } from '../../router'
import { LocationState } from '../MyCommunity/types'
import { CategoryDecision, ContractCategoriesProps } from './types'
import './styles.scss'

export const ContractCategories: FC<ContractCategoriesProps> = ({
  initialState,
  isActive,
  setIsActive,
  contractCategoriesList
}) => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [categoriesToAdd, setCategoriesToAdd] = useState([])
  const [selectedCategories, setSelectedCategories] = useState(initialState)
  const selectedContractCategories = useMemo(
    () =>
      contractCategoriesList.filter((i) =>
        selectedCategories.categories.includes(i.uuid)
      ),
    [selectedCategories, contractCategoriesList]
  )
  const selectedPendingCategories = useMemo(
    () =>
      contractCategoriesList
        .filter(
          (i) =>
            !!selectedCategories.pendingCategories.find(
              (j) => j.uuid === i.uuid
            )
        )
        .map((i) => ({
          ...i,
          ...selectedCategories.pendingCategories.find((j) => j.uuid === i.uuid)
        })),
    [selectedCategories, contractCategoriesList]
  )
  const approvedPendingCategories = selectedPendingCategories.filter(
    (i) => i.decision === 'approve'
  )
  const unResolvedPendingCategories = selectedPendingCategories.filter(
    (i) => !i.decision
  )
  const contractCategories = useMemo(
    () => [...selectedContractCategories, ...approvedPendingCategories],
    [selectedContractCategories, approvedPendingCategories]
  )
  const categoriesOptions = useMemo(
    () =>
      returnSelectOptions(contractCategoriesList, [
        ...contractCategories.map((i) => i.uuid),
        ...unResolvedPendingCategories.map((i) => i.uuid)
      ]),
    [contractCategoriesList, contractCategories, unResolvedPendingCategories]
  )
  const dispatch = useDispatch()
  const { id } = useParams()
  const { push, location } = useRouter()
  const locationState = location.state as LocationState
  const discardChangesPopup = usePopup()
  const [showPopup, confirmNavigation, cancelNavigation] =
    useShowPopup(isActive)

  useEffect(() => {
    setIsActive(
      JSON.stringify(selectedCategories) !== JSON.stringify(initialState)
    )
  }, [selectedCategories])

  const cancelDiscardChanges = () => discardChangesPopup.actions.close()

  const onAddCategories = () => {
    setSelectedCategories((prev) => ({
      ...prev,
      categories: [...prev.categories, ...categoriesToAdd]
    }))
    onCloseModal()
  }

  const onCloseModal = () => {
    setCategoriesToAdd([])
    setIsAddModalOpen(false)
  }

  const onChangeDecision = (id: string, decision: CategoryDecision) => {
    setSelectedCategories((prev) => {
      const idx = prev.pendingCategories.findIndex((i) => i.uuid === id)
      const changedCategory = { ...prev.pendingCategories[idx] }
      changedCategory.decision = decision

      return {
        ...prev,
        pendingCategories: [
          ...prev.pendingCategories.slice(0, idx),
          changedCategory,
          ...prev.pendingCategories.slice(idx + 1)
        ]
      }
    })
  }

  const submitCancellation = () => {
    setIsActive(false)
    setSelectedCategories(initialState)
    discardChangesPopup.actions.close()
  }

  const resetFields = () => {
    if (isActive) {
      discardChangesPopup.actions.open()
    } else {
      submitCancellation()
    }
  }

  const onApproveCategories = async () => {
    try {
      dispatch(setLoading(true))
      const pendingCategories = selectedPendingCategories
        .filter((i) => !!i.decision)
        .map((i) => ({
          id: i.uuid,
          decision: i.decision as CategoryDecision
        }))
      if (pendingCategories.length) {
        await approveContractCategories(id as string, pendingCategories)
      }
      setIsActive(false)
      await updateUser(id as string, {
        contract_categories: selectedContractCategories.map((i) => i.uuid)
      })
      const selectedHs = locationState.selectedHs
      push(`${routes.myCommunity}/health-system/${selectedHs}`)
    } finally {
      dispatch(setLoading(false))
    }
  }

  const onRemoveCategory = (id: string, isPending?: boolean) => {
    if (isPending) {
      setSelectedCategories((prev) => {
        const idx = prev.pendingCategories.findIndex((i) => i.uuid === id)
        const item = { ...prev.pendingCategories[idx], decision: undefined }
        return {
          ...prev,
          pendingCategories: [
            ...prev.pendingCategories.slice(0, idx),
            item,
            ...prev.pendingCategories.slice(idx + 1)
          ]
        }
      })
    } else {
      setSelectedCategories((prev) => {
        const idx = prev.categories.findIndex((i) => i === id)
        return {
          ...prev,
          categories: [
            ...prev.categories.slice(0, idx),
            ...prev.categories.slice(idx + 1)
          ]
        }
      })
    }
  }

  return (
    <div className="contract-categories column gap-32">
      {!!unResolvedPendingCategories.length && (
        <Can I={ACTIONS.CRUD} a={SUBJECTS.APPROVE_PENDING_USERS}>
          <div className="column gap-24">
            <Typography.Headline6>
              Pending contract categories ({unResolvedPendingCategories.length})
            </Typography.Headline6>
            <div className="column gap-8">
              {unResolvedPendingCategories.map((i) => (
                <ContractCategory
                  key={i.uuid}
                  id={i.uuid}
                  label={i.name}
                  onApproveCategory={(id) => onChangeDecision(id, 'approve')}
                  onRemoveCategory={(id) => onChangeDecision(id, 'decline')}
                  disabled={!i.can_be_approved}
                  isPending
                />
              ))}
            </div>
          </div>
        </Can>
      )}
      <div className="column gap-24">
        <Typography.Headline6>
          Contract categories ({contractCategories?.length})
        </Typography.Headline6>
        {!!contractCategories?.length && (
          <div className="column gap-8">
            {contractCategories.map((i) => (
              <ContractCategory
                key={i.uuid}
                id={i.uuid}
                label={i.name}
                onRemoveCategory={onRemoveCategory}
                isPending={i.can_be_approved}
              />
            ))}
          </div>
        )}
      </div>
      <Button
        className="contract-categories__add-btn"
        onClick={() => setIsAddModalOpen(true)}
        type={BUTTON_TYPES.GHOST}
        icon={<Plus16 />}
        upperCase
        medium
      >
        {BTN_TXT.ADD_CONTRACT_CATEGORY}
      </Button>
      <div
        className={cn(
          'contract-categories__actions row align-center justify-end gap-12',
          {
            'contract-categories__actions--hidden': !isActive
          }
        )}
      >
        <Button onClick={resetFields} type={BUTTON_TYPES.DEFAULT} upperCase>
          {BTN_TXT.CANCEL}
        </Button>
        <Button
          type={BUTTON_TYPES.PRIMARY}
          onClick={onApproveCategories}
          upperCase
        >
          {BTN_TXT.SAVE}
        </Button>
      </div>
      <Popup
        title="Add Contract category"
        className="contract-categories__categories-popup"
        visible={isAddModalOpen}
        onCancel={onCloseModal}
        footer={
          <>
            <Button
              onClick={onCloseModal}
              type={BUTTON_TYPES.DEFAULT}
              upperCase
            >
              {BTN_TXT.CANCEL}
            </Button>
            <Button
              type={BUTTON_TYPES.PRIMARY}
              onClick={onAddCategories}
              upperCase
            >
              {BTN_TXT.ADD}
            </Button>
          </>
        }
      >
        <Select.Multi
          options={categoriesOptions}
          propsItem={{ label: LABELS.CONTRACT_CATEGORIES }}
          propsSelect={{
            placeholder: PLACEHOLDERS.PLEASE_SELECT,
            value: categoriesToAdd,
            onChange: (values) => setCategoriesToAdd(values),
            allowClear: true
          }}
        />
      </Popup>
      <Popup.Discard
        visible={showPopup ? showPopup : discardChangesPopup.state.visible}
        onCancel={showPopup ? cancelNavigation : cancelDiscardChanges}
        onSubmit={showPopup ? confirmNavigation : submitCancellation}
      />
    </div>
  )
}
